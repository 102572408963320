
















import { Observer } from 'mobx-vue'
import { Component, Vue } from 'vue-property-decorator'
import { invitationController } from './invitation-controller'

@Observer
@Component({
  components: {
    'invite-page': () => import('./invite.vue'),
    'create-period': () => import('./create-period.vue'),
    'user-invite': () => import('./user-invite.vue'),
  },
})
export default class extends Vue {
  controller = invitationController
  dialog = false
}
